<div class="dsh-accordion-item" (click)="expand()" [ngClass]="{ 'dsh-accordion-item-expanded': expanded }">
    <div *ngIf="expandedChange | async; then content; else panelBase"></div>
    <ng-template #content>
        <div @expand class="dsh-accordion-item-content">
            <ng-container
                *ngTemplateOutlet="accordionItemContent?.templateRef || lazyContent?._template"
            ></ng-container>
        </div>
    </ng-template>
    <ng-template #panelBase>
        <div @expand>
            <ng-content></ng-content>
        </div>
    </ng-template>
</div>
