<div
    *transloco="let t; scope: 'api-model-details'; read: 'apiModelDetails.payoutToolDetails.internationalBankAccount'"
    fxLayout="column"
    fxLayoutGap="24px"
>
    <dsh-details-item *ngIf="internationalBankAccount?.number" [title]="t('number')">
        {{ internationalBankAccount?.number }}
    </dsh-details-item>
    <dsh-details-item *ngIf="internationalBankAccount?.iban" [title]="t('iban')">
        {{ internationalBankAccount?.iban }}
    </dsh-details-item>
    <ng-container *ngIf="internationalBankAccount?.bankDetails as bankDetails">
        <ng-container
            *transloco="
                let d;
                scope: 'api-model-details';
                read: 'apiModelDetails.payoutToolDetails.internationalBankAccount.bankDetails'
            "
            gdColumns="1fr"
            gdColumns.gt-sm="1fr 1fr 1fr"
            gdGap="24px"
        >
            <dsh-details-item *ngIf="bankDetails?.name" [title]="d('name')">
                {{ bankDetails?.name }}
            </dsh-details-item>
            <dsh-details-item *ngIf="bankDetails?.bic" [title]="d('bic')">
                {{ bankDetails?.bic }}
            </dsh-details-item>
            <dsh-details-item *ngIf="bankDetails?.abartn" [title]="d('abartn')">
                {{ bankDetails?.abartn }}
            </dsh-details-item>
            <dsh-details-item gdColumn.gt-sm="1/-1" *ngIf="bankDetails?.address" [title]="d('address')">
                {{ bankDetails?.address }}
            </dsh-details-item>
            <dsh-details-item gdColumn.gt-sm="1/-1" *ngIf="bankDetails?.countryCode" [title]="d('countryCode')">
                {{ bankDetails?.countryCode }}
            </dsh-details-item>
        </ng-container>
    </ng-container>
    <div *ngIf="internationalBankAccount?.correspondentBankAccount" fxLayout="column" fxLayoutGap="24px">
        <mat-divider></mat-divider>
        <div class="mat-title">{{ t('correspondentBankAccount') }}</div>
        <dsh-international-bank-account
            [internationalBankAccount]="internationalBankAccount?.correspondentBankAccount"
        ></dsh-international-bank-account>
    </div>
</div>
