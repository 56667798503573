<div fxLayout="column">
    <div class="content">
        <ng-content></ng-content>
    </div>

    <mat-divider></mat-divider>

    <dsh-actions class="actions" *transloco="let t">
        <button *ngIf="!noClearButton" dsh-text-button color="accent" (click)="clear.emit($event)">
            {{ t('clear') }}
        </button>
        <button dsh-button color="accent" (click)="save.emit($event)">
            {{ t('save') }}
        </button>
    </dsh-actions>
</div>
