<dsh-filter
    *transloco="let t; scope: 'currency-filter'; read: 'currencyFilter'"
    [active]="isActive"
    [label]="t('label')"
    [activeLabel]="activeLabel$ | async"
    [content]="content"
    (save)="save()"
    (clear)="clear()"
    noClearButton
>
</dsh-filter>
<ng-template #content>
    <dsh-currency-field [formControl]="control" [currencies]="currencies"></dsh-currency-field>
</ng-template>
