<div class="dsh-toolbar" fxLayout fxLayoutGap="24px">
    <dsh-brand fxFlex fxLayoutAlign="start"></dsh-brand>
    <div fxFlex fxLayoutAlign="center">
        <nav class="dsh-top-tab-nav-bar" mat-tab-nav-bar>
            <a
                *ngFor="let link of sectionLinks$ | async"
                mat-tab-link
                [routerLink]="link.path"
                routerLinkActive
                [routerLinkActiveOptions]="{ exact: link?.exact }"
                #rla="routerLinkActive"
                [active]="rla.isActive"
            >
                {{ link.label }}
            </a>
        </nav>
    </div>
    <dsh-actionbar fxFlex fxLayoutAlign="end"></dsh-actionbar>
</div>
